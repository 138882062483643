<template>
  <div>
    <b-overlay
      :show="false"
      rounded="sm"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
    >
      <b-table
        small
        striped
        bordered
        outlined
        class="cs-btable mt-2"
        responsive
        :items="reportData"
        :fields="tableColumns"
      >
        <template #cell(index)="data">
          <span class="text-capitalize">{{ data.index+1 }}</span>
        </template>
        <template #cell(expired_date)="data">
          <span>{{ formatExpiredDate(data.item) }}</span>
        </template>
        <template #cell(lot_number)="data">
          <span>{{ (data.item.lot_number)||'' }}</span>
        </template>
        <template #cell(stock_in_price)="data">
          <span>{{ Number(data.item.stock_in_price)?appUtils.numberFormat(data.item.stock_in_price):'' }}</span>
        </template>
        <template #cell(stock_in_total)="data">
          <span>{{ Number(data.item.stock_in_total)?appUtils.numberFormat(data.item.stock_in_total):'' }}</span>
        </template>
        <template #cell(stock_out_price)="data">
          <span>{{ Number(data.item.stock_out_price)?appUtils.numberFormat(data.item.stock_out_price):'' }}</span>
        </template>
        <template #cell(stock_out_total)="data">
          <span>{{ Number(data.item.stock_out_total)?appUtils.numberFormat(data.item.stock_out_total):'' }}</span>
        </template>
        <template #cell(stock_out_num)="data">
          <span>{{ Number(data.item.stock_out_num)?appUtils.numberFormat(data.item.stock_out_num):'' }}</span>
        </template>
      </b-table>
    </b-overlay>
  </div>
</template>

<script>
import appUtils from "@/utils/appUtils";
import ExcelJS from "exceljs";

const tableColumns = [
  { key: "index", label: "STT", sortable: false },
  {
    key: "product_code",
    label: "Mã thuốc",
    sortable: false
  },
  {
    key: "name",
    label: "Tên thuốc",
    sortable: false
  },
  {
    key: "quality",
    label: "Hàm lượng",
    sortable: false
  },
  {
    key: "active_ingredient",
    label: "Tên hoạt chất",
    sortable: false
  },
  {
    key: "country",
    label: "Nước sản xuất",
    sortable: false
  },
  {
    key: "manufacturer_name",
    label: "Hãng sản xuất",
    sortable: false
  },
  {
    key: "lot_number",
    label: "Số lô",
    sortable: false
  },
  {
    key: "expired_date",
    label: "Hạn sử dụng",
    sortable: false
  },
  {
    key: "unit_name",
    label: "Đơn vị tính",
    sortable: false
  },
  {
    key: "stock_out_num",
    label: "Số lượng",
    sortable: false
  },
  {
    key: "stock_in_price",
    label: "Giá nhập",
    sortable: false
  },
  {
    key: "stock_in_total",
    label: "Thành tiền nhập",
    sortable: false
  },
  {
    key: "stock_out_price",
    label: "Giá bán",
    sortable: false
  },
  {
    key: "stock_out_total",
    label: "Thành tiền bán",
    sortable: false
  }
];
export default {
  name: "PharmaRevenue",
  props: {
    reportData: Array,
    countExport: Number,
    title: String,
    inventoryName: String,
    fromDate: String,
    toDate: String,
    allData: Array
  },
  watch: {
    countExport() {
      this.exportExcel();
    }
  },
  data() {
    return {
      tableColumns,
      appUtils
    };
  },
  methods: {
    async exportExcel() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet1", {
        views: [{ showGridLines: false }]
      });

      // Access columns from B to J and set their widths
      const defaultColWidth = 18;
      for (let index = 1; index <= 26; index++) {
        const column = worksheet.getColumn(index + 1); // Columns are 1-indexed
        if ([2, 5, 6].includes(index)) {
          column.width = 28;
        } else {
          column.width = defaultColWidth;
        }
      }

      worksheet.properties.defaultRowHeight = 16;
      const START_ROW = 2;

      // Add a header rows and merged cells with style before main table
      const nameCell = worksheet.getCell(`E${START_ROW}`);
      nameCell.value = this.title.toUpperCase();
      nameCell.font = { size: 20, bold: true };
      worksheet.mergeCells(`E${START_ROW}:J${START_ROW}`);

      const inventoryNameCell = worksheet.getCell(`E${START_ROW + 1}`);
      inventoryNameCell.value = this.inventoryName?.toUpperCase();
      inventoryNameCell.font = { size: 12, bold: true };
      worksheet.mergeCells(`E${START_ROW + 1}:J${START_ROW + 1}`);

      const dateCell = worksheet.getCell(`E${START_ROW + 2}`);
      dateCell.value = `Từ ${this.fromDate} đến ${this.toDate}`;
      dateCell.font = { bold: false, italic: true };
      worksheet.mergeCells(`E${START_ROW + 2}:J${START_ROW + 2}`);

      const rawHeader = this.tableColumns.map(item => item.label);
      // Generate the main table (your data) with borders and style
      const rawData = this.allData.map((item, i) => {
        return [
          i + 1,
          item.product_code || "",
          item.name || "",
          item.quality || "",
          item.active_ingredient || "",
          item.country || "",
          item.manufacturer_name || "",
          item.lot_number || "",
          this.formatExpiredDate(item) || "",
          item.unit_name || "",
          item.stock_out_num || "",
          Number(item.stock_in_price)
            ? appUtils.numberFormat(item.stock_in_price)
            : "",
          Number(item.stock_in_total)
            ? appUtils.numberFormat(item.stock_in_total)
            : "",
          Number(item.stock_out_price)
            ? appUtils.numberFormat(item.stock_out_price)
            : "",
          Number(item.stock_out_total)
            ? appUtils.numberFormat(item.stock_out_total)
            : ""
        ];
      });
      const mainTableData = [
        [], // empty row
        rawHeader,
        ...rawData
      ];

      worksheet.addRows(mainTableData);
      const mainTable = worksheet.getRows(6, mainTableData.length - 1);
      mainTable.forEach((row, rowIndex) => {
        row.eachCell(cell => {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
            bottom: { style: "thin" }
          };
          cell.font = { size: 12 };
          if (!rowIndex) {
            cell.font = { bold: true };
          }
        });
      });
      // default styles
      workbook.eachSheet(sheet => {
        sheet.eachRow(row => {
          row.alignment = {
            horizontal: "center",
            vertical: "middle",
            wrapText: true
          };
          row.eachCell(cell => {
            if (!cell.font?.name) {
              cell.font = Object.assign(cell.font || {}, {
                name: "Times New Roman"
                // size: 12
              });
            }
          });
        });
      });

      // Save the workbook to a file
      await workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "BaoCaoDoanhThuNhaThuoc.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    formatExpiredDate(data) {
      if (
        !Number(data?.expiry_date_day) &&
        !Number(data.expiry_date_month) &&
        !Number(data?.expiry_date_year)
      )
        return "";

      const date =
        Number(data?.expiry_date_day) && Number(data?.expiry_date_day) < 10
          ? `0${data?.expiry_date_day}/`
          : data?.expiry_date_day
          ? `${data?.expiry_date_day}/`
          : "";

      const month =
        Number(data?.expiry_date_month) && Number(data?.expiry_date_month) < 10
          ? `0${data?.expiry_date_month}/`
          : data?.expiry_date_month
          ? `${data?.expiry_date_month}/`
          : "";
      return `${date}${month}${data?.expiry_date_year || ""}`;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>