<template>
  <div>
    <b-overlay
      :show="false"
      rounded="sm"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
    >
      <b-table
        small
        striped
        bordered
        outlined
        class="cs-btable mt-2"
        responsive
        :items="reportData"
        :fields="tableColumns"
      >
        <template #cell(index)="data">
          <span class="text-capitalize">{{ data.index+1 }}</span>
        </template>
        <template #cell(lot_number)="data">
          <span>{{ (data.item.lot_number)||'' }}</span>
        </template>
        <template #cell(expired_date)="data">
          <span>{{ formatExpiredDate(data.item) }}</span>
        </template>
        <template #cell(price_in)="data">
          <span>{{ appUtils.numberFormat(data.item.price_in)||'' }}</span>
        </template>
        <template #cell(vat_in)="data">
          <span>{{ Number(data.item.vat_in)||'' }}</span>
        </template>
        <template #cell(total_price_in)="data">
          <span>{{ appUtils.numberFormat(data.item.total_price_in)||'' }}</span>
        </template>
      </b-table>
    </b-overlay>
  </div>
</template>

<script>
import appUtils from "@/utils/appUtils";
import ExcelJS from "exceljs";

const tableColumns = [
  { key: "index", label: "STT", sortable: false },
  {
    key: "inventory_code",
    label: "Mã Kho",
    sortable: false
  },
  {
    key: "inventory_name",
    label: "Tên Kho",
    sortable: false
  },
  {
    key: "supplier_code",
    label: "Mã NCC",
    sortable: false
  },
  {
    key: "supplier_name",
    label: "Tên NCC",
    sortable: false
  },
  {
    key: "country_name",
    label: "Nước sản xuất",
    sortable: false
  },
  {
    key: "manufacture_name",
    label: "Hãng sản xuất",
    sortable: false
  },
  {
    key: "active_ingredient",
    label: "Hoạt chất",
    sortable: false
  },
  {
    key: "receive_date",
    label: "Ngày nhập",
    sortable: false
  },
  {
    key: "invoice_date",
    label: "Ngày HĐ",
    sortable: false
  },
  {
    key: "invoice_code",
    label: "Số HĐ",
    sortable: false
  },
  {
    key: "receiving_id",
    label: "ID Phiếu",
    sortable: false
  },
  // {
  //   key: "content",
  //   label: "Nội dung",
  //   sortable: false
  // },
  // {
  //   key: "collected_name",
  //   label: "Người giao",
  //   sortable: false
  // },
  {
    key: "receiver_name",
    label: "Người nhận",
    sortable: false
  },
  {
    key: "product_code",
    label: "Mã Thuốc",
    sortable: false
  },
  {
    key: "name",
    label: "Tên Thuốc và Hàm lượng",
    sortable: false
  },
  {
    key: "lot_number",
    label: "Số lô",
    sortable: false
  },
  {
    key: "expired_date",
    label: "Hạn SD",
    sortable: false
  },
  {
    key: "qty",
    label: "Số lượng",
    sortable: false
  },
  {
    key: "unit_name",
    label: "Đơn vị tính",
    sortable: false
  },
  {
    key: "price_in",
    label: "Đơn giá",
    sortable: false
  },
  {
    key: "vat_in",
    label: "Vat(%)",
    sortable: false
  },
  {
    key: "total_price_in",
    label: "Thành tiền",
    sortable: false
  }
];

export default {
  name: "MedicineSupplierReport",
  props: {
    reportData: Array,
    countExport: Number,
    title: String,
    inventoryName: String,
    fromDate: String,
    toDate: String
  },
  watch: {
    countExport() {
      this.exportExcel();
    }
  },
  data() {
    return {
      tableColumns,
      appUtils
    };
  },
  methods: {
    async exportExcel() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet1", {
        views: [{ showGridLines: false }]
      });

      // Access columns from B to J and set their widths
      const defaultColWidth = 14;
      for (let index = 1; index <= 26; index++) {
        const column = worksheet.getColumn(index + 1); // Columns are 1-indexed
        if ([2, 16].includes(index)) {
          column.width = 28;
        } else {
          column.width = defaultColWidth;
        }
      }

      worksheet.properties.defaultRowHeight = 16;
      const START_ROW = 2;

      // Add a header rows and merged cells with style before main table
      const nameCell = worksheet.getCell(`F${START_ROW}`);
      nameCell.value = this.title.toUpperCase();
      nameCell.font = { size: 20, bold: true };
      worksheet.mergeCells(`F${START_ROW}:L${START_ROW}`);

      const inventoryNameCell = worksheet.getCell(`F${START_ROW + 1}`);
      inventoryNameCell.value = this.inventoryName?.toUpperCase();
      inventoryNameCell.font = { size: 12, bold: true };
      worksheet.mergeCells(`F${START_ROW + 1}:L${START_ROW + 1}`);

      const dateCell = worksheet.getCell(`F${START_ROW + 2}`);
      dateCell.value = `Từ ${this.fromDate} đến ${this.toDate}`;
      dateCell.font = { bold: false, italic: true };
      worksheet.mergeCells(`F${START_ROW + 2}:L${START_ROW + 2}`);

      const rawHeader = this.tableColumns.map(item => item.label);
      // Generate the main table (your data) with borders and style
      const rawData = this.reportData.map((item, i) => {
        return [
          i + 1,
          item.inventory_code || "",
          item.inventory_name || "",
          item.supplier_code || "",
          item.supplier_name || "",
          item.country_name || "",
          item.manufacture_name || "",
          item.active_ingredient || "",
          item.receive_date || "",
          item.invoice_date || "",
          item.invoice_code || "",
          item.receiving_id || "",
          item.receiver_name || "",
          item.product_code || "",
          item.name || "",
          item.lot_number || "",
          this.formatExpiredDate(item) || "",
          Number(item.qty) || "",
          item.unit_name || "",
          appUtils.numberFormat(item.price_in) || "",
          Number(item.vat_in) || "",
          appUtils.numberFormat(item.total_price_in) || ""
        ];
      });
      const mainTableData = [
        [], // empty row
        rawHeader,
        ...rawData
      ];

      worksheet.addRows(mainTableData);
      const mainTable = worksheet.getRows(6, mainTableData.length - 1);
      mainTable.forEach((row, rowIndex) => {
        row.eachCell(cell => {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
            bottom: { style: "thin" }
          };
          cell.font = { size: 12 };
          if (!rowIndex) {
            cell.font = { bold: true };
          }
        });
      });
      // default styles
      workbook.eachSheet(sheet => {
        sheet.eachRow(row => {
          row.alignment = {
            horizontal: "center",
            vertical: "middle",
            wrapText: true
          };
          row.eachCell(cell => {
            if (!cell.font?.name) {
              cell.font = Object.assign(cell.font || {}, {
                name: "Times New Roman"
                // size: 12
              });
            }
          });
        });
      });

      // Save the workbook to a file
      await workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "BaoCaoThuocNhapNhaCungCap.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    formatExpiredDate(data) {
      if (
        !Number(data?.expiry_date_day) &&
        !Number(data.expiry_date_month) &&
        !Number(data?.expiry_date_year)
      )
        return "";

      const date =
        Number(data?.expiry_date_day) && Number(data?.expiry_date_day) < 10
          ? `0${data?.expiry_date_day}/`
          : data?.expiry_date_day
          ? `${data?.expiry_date_day}/`
          : "";

      const month =
        Number(data?.expiry_date_month) && Number(data?.expiry_date_month) < 10
          ? `0${data?.expiry_date_month}/`
          : data?.expiry_date_month
          ? `${data?.expiry_date_month}/`
          : "";
      return `${date}${month}${data?.expiry_date_year || ""}`;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>