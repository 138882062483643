<template>
  <div>
    <b-overlay
      :show="false"
      rounded="sm"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
    >
      <b-table
        small
        striped
        bordered
        outlined
        class="cs-table mt-2"
        responsive
        :items="reportData"
        :fields="tableColumns"
      >
        <template #cell(index)="data">
          <span class="text-capitalize">{{ data.index+1 }}</span>
        </template>
        <template #cell(pay_at)="data">
          <span class="text-capitalize">{{ formatDate(data.item.pay_at) }}</span>
        </template>
        <template #cell(pay_type)="data">
          <span class="text-capitalize">{{ data.item.pay_type===1?'Bệnh nhân':'Khách lẻ' }}</span>
        </template>
        <template #cell(purchase_type)>
          <span class="text-capitalize">{{ 'Tiền mặt' }}</span>
        </template>
        <template #cell(total_amount)="data">
          <span class="text-capitalize">{{ formatPrice(data.item.total_amount) }}</span>
        </template>
      </b-table>
    </b-overlay>
  </div>
</template>
  
  <script>
import appUtils from "@/utils/appUtils";
import ExcelJS from "exceljs";

const tableColumns = [
  { key: "index", label: "STT", sortable: false },
  {
    key: "pay_at",
    label: "Ngày thu",
    sortable: false
  },
  {
    key: "pay_code",
    label: "Mã Phiếu thu",
    sortable: false
  },
  {
    key: "pay_type",
    label: "Loại xuất",
    sortable: false
  },
  {
    key: "purchase_type",
    label: "Phương thức thanh toán",
    sortable: false
  },
  {
    key: "collected_name",
    label: "Thu ngân",
    sortable: false
  },
  {
    key: "total_amount",
    label: "Tổng tiền",
    sortable: false
  }
];

export default {
  name: "PharmaRevenueV2",
  props: {
    reportData: Array,
    countExport: Number,
    title: String,
    inventoryName: String,
    fromDate: String,
    toDate: String,
    allData: Array
  },
  watch: {
    countExport() {
      this.exportExcel();
    }
  },
  data() {
    return {
      tableColumns
    };
  },
  methods: {
    async exportExcel() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet1", {
        views: [{ showGridLines: false }],
        pageSetup: {
          horizontalCentered: true,
          verticalCentered: true
        }
      });

      // Access columns from B to J and set their widths
      const defaultColWidth = 20;
      for (let index = 1; index <= 26; index++) {
        const column = worksheet.getColumn(index + 1); // Columns are 1-indexed
        if ([1, 4, 7].includes(index)) {
          column.width = 28;
        } else {
          column.width = defaultColWidth;
        }
      }

      worksheet.properties.defaultRowHeight = 16;
      const START_ROW = 2;

      // Add a header rows and merged cells with style before main table
      const nameCell = worksheet.getCell(`C${START_ROW}`);
      nameCell.value = this.title.toUpperCase();
      nameCell.font = { size: 20, bold: true };
      worksheet.mergeCells(`C${START_ROW}:F${START_ROW}`);

      const inventoryNameCell = worksheet.getCell(`C${START_ROW + 1}`);
      inventoryNameCell.value = this.inventoryName?.toUpperCase();
      inventoryNameCell.font = { size: 12, bold: true };
      worksheet.mergeCells(`C${START_ROW + 1}:F${START_ROW + 1}`);

      const dateCell = worksheet.getCell(`C${START_ROW + 2}`);
      dateCell.value = `Từ ${this.fromDate} đến ${this.toDate}`;
      dateCell.font = { bold: false, italic: true };
      worksheet.mergeCells(`C${START_ROW + 2}:F${START_ROW + 2}`);

      const rawHeader = this.tableColumns.map(item => item.label);
      // Generate the main table (your data) with borders and style
      const rawData = this.allData.map((item, i) => {
        return [
          i + 1,
          this.formatDate(item.pay_at) || "",
          item.pay_code || "",
          item.pay_type === 1 ? "Bệnh nhân" : "Khách lẻ",
          "Tiền mặt",
          item.collected_name || "",
          this.formatPrice(item.total_amount)
        ];
      });
      const mainTableData = [
        [], // empty row
        rawHeader,
        ...rawData
      ];

      worksheet.addRows(mainTableData);
      const mainTable = worksheet.getRows(6, mainTableData.length - 1);
      mainTable.forEach((row, rowIndex) => {
        row.eachCell(cell => {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
            bottom: { style: "thin" }
          };
          cell.font = { size: 12 };
          if (!rowIndex) {
            cell.font = { bold: true };
          }
        });
      });
      // default styles
      workbook.eachSheet(sheet => {
        sheet.eachRow(row => {
          row.alignment = {
            horizontal: "center",
            vertical: "middle",
            wrapText: true
          };
          row.eachCell(cell => {
            if (!cell.font?.name) {
              cell.font = Object.assign(cell.font || {}, {
                name: "Times New Roman"
                // size: 12
              });
            }
          });
        });
      });

      // Save the workbook to a file
      await workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "BaoCaoDoanhThu.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    formatDateTime(date) {
      if (!date) return;
      return window.moment(date).format("HH:mm DD/MM/YYYY");
    },
    formatPrice(price) {
      return appUtils.numberFormat(Number(price), "VNĐ");
    },
    formatDate(date) {
      if (!date) return;
      return window.moment(date).format("DD/MM/YYYY");
    }
  }
};
</script>
  
  <style lang="scss" scoped>
::v-deep .cs-table * {
  color: #000;
  font-family: "Times New Roman", Times, serif;

  thead * {
    font-size: 18px;
    text-transform: none !important;
  }

  tbody {
    font-size: 18px;
  }
}
</style>