<template>
  <div>
    <b-overlay
      :show="false"
      rounded="sm"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
    >
      <b-table
        small
        striped
        bordered
        outlined
        class="cs-btable mt-2"
        responsive
        :items="reportData"
        :fields="tableColumns"
      >
        <template #cell(index)="data">
          <span class="text-capitalize">{{ data.index+1 }}</span>
        </template>
        <template #cell(receipt_date)="data">
          <span class="text-capitalize">{{ formatDateTime(data.item.receipt_date) }}</span>
        </template>
        <template #cell(amount_received)="data">
          <span class="text-capitalize">{{ formatPrice(data.item.amount_received) }}</span>
        </template>
        <template #cell(exemption_amount)="data">
          <span class="text-capitalize">{{ formatPrice(data.item.exemption_amount) }}</span>
        </template>
        <template #cell(cancelled_at)="data">
          <span class="text-capitalize">{{ formatDateTime(data.item.cancelled_at) }}</span>
        </template>
        <template #cell(cancelled_amount)="data">
          <span class="text-capitalize">{{ formatPrice(data.item.cancelled_amount) }}</span>
        </template>
      </b-table>
    </b-overlay>
  </div>
</template>

<script>
import appUtils from "@/utils/appUtils";
import ExcelJS from "exceljs";

const tableColumns = [
  { key: "index", label: "STT", sortable: false },
  {
    key: "customer_name",
    label: "Tên khách hàng",
    sortable: false
  },
  // {
  //   key: "customer_age",
  //   label: "Tuổi",
  //   sortable: false
  // },
  // {
  //   key: "customer_gender",
  //   label: "Giới tính",
  //   sortable: false
  // },
  // {
  //   key: "customer_phone",
  //   label: "Điện thoại",
  //   sortable: false
  // },
  // {
  //   key: "customer_address",
  //   label: "Địa chỉ",
  //   sortable: false
  // },
  {
    key: "id",
    label: "Số phiếu",
    sortable: false
  },
  {
    key: "collected_user.name",
    label: "Người thu",
    sortable: false
  },
  {
    key: "receipt_date",
    label: "Ngày thu",
    sortable: false
  },
  {
    key: "amount_received",
    label: "Số tiền thu",
    sortable: false
  },
  {
    key: "exemption_amount",
    label: "Số tiền miễn giảm",
    sortable: false
  },
  {
    key: "exemption_reason",
    label: "Lý do miễn giảm",
    sortable: false
  },
  // {
  //   key: "payment_method",
  //   label: "Hình thức thu",
  //   sortable: false
  // },
  // {
  //   key: "receipt_book",
  //   label: "Sổ thu",
  //   sortable: false
  // },
  {
    key: "cancelled_by",
    label: "Người huỷ",
    sortable: false
  },
  {
    key: "cancelled_at",
    label: "Ngày huỷ",
    sortable: false
  },
  {
    key: "cancelled_amount",
    label: "Số tiền huỷ",
    sortable: false
  },
  {
    key: "cancelled_reason",
    label: "Lý do huỷ",
    sortable: false
  }
];

export default {
  name: "CashierReport",
  props: {
    reportData: Array,
    countExport: Number,
    title: String,
    inventoryName: String,
    fromDate: String,
    toDate: String,
    allData: Array
  },
  watch: {
    countExport() {
      this.exportExcel();
    }
  },
  data() {
    return {
      tableColumns
    };
  },
  methods: {
    async exportExcel() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet1", {
        views: [{ showGridLines: false }]
      });

      // Access columns from B to J and set their widths
      const defaultColWidth = 20;
      for (let index = 1; index <= 26; index++) {
        const column = worksheet.getColumn(index + 1); // Columns are 1-indexed
        if ([1, 3, 7].includes(index)) {
          column.width = 28;
        } else {
          column.width = defaultColWidth;
        }
      }

      worksheet.properties.defaultRowHeight = 16;
      const START_ROW = 2;

      // Add a header rows and merged cells with style before main table
      const nameCell = worksheet.getCell(`E${START_ROW}`);
      nameCell.value = this.title.toUpperCase();
      nameCell.font = { size: 20, bold: true };
      worksheet.mergeCells(`E${START_ROW}:H${START_ROW}`);

      const inventoryNameCell = worksheet.getCell(`E${START_ROW + 1}`);
      inventoryNameCell.value = this.inventoryName?.toUpperCase();
      inventoryNameCell.font = { size: 12, bold: true };
      worksheet.mergeCells(`E${START_ROW + 1}:H${START_ROW + 1}`);

      const dateCell = worksheet.getCell(`E${START_ROW + 2}`);
      dateCell.value = `Từ ${this.fromDate} đến ${this.toDate}`;
      dateCell.font = { bold: false, italic: true };
      worksheet.mergeCells(`E${START_ROW + 2}:H${START_ROW + 2}`);

      const rawHeader = this.tableColumns.map(item => item.label);
      // Generate the main table (your data) with borders and style
      const rawData = this.allData.map((item, i) => {
        return [
          i + 1,
          item?.customer_name || "",
          item?.id || "",
          item?.collected_user?.name || "",
          this.formatDateTime(item?.receipt_date) || "",
          this.formatPrice(item?.amount_received) || "",
          this.formatPrice(item?.exemption_amount) || "",
          item?.exemption_reason || "",
          item?.cancelled_by || "",
          this.formatDateTime(item?.cancelled_at) || "",
          this.formatPrice(item?.cancelled_amount) || "",
          item?.cancelled_reason || ""
        ];
      });
      const mainTableData = [
        [], // empty row
        rawHeader,
        ...rawData
      ];

      worksheet.addRows(mainTableData);
      const mainTable = worksheet.getRows(6, mainTableData.length - 1);
      mainTable.forEach((row, rowIndex) => {
        row.eachCell(cell => {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
            bottom: { style: "thin" }
          };
          cell.font = { size: 12 };
          if (!rowIndex) {
            cell.font = { bold: true };
          }
        });
      });
      // default styles
      workbook.eachSheet(sheet => {
        sheet.eachRow(row => {
          row.alignment = {
            horizontal: "center",
            vertical: "middle",
            wrapText: true
          };
          row.eachCell(cell => {
            if (!cell.font?.name) {
              cell.font = Object.assign(cell.font || {}, {
                name: "Times New Roman"
                // size: 12
              });
            }
          });
        });
      });

      // Save the workbook to a file
      await workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "BaoCaoThuNganNhaThuoc.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    formatDateTime(date) {
      if (!date) return;
      return window.moment(date).format("HH:mm DD/MM/YYYY");
    },
    formatPrice(price) {
      return appUtils.numberFormat(Number(price), "VNĐ");
    }
  }
};
</script>

<style lang="scss" scoped>
</style>