<template>
  <b-overlay
    :show="isLoading"
    rounded="sm"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
  >
    <b-card class="cs-print">
      <div class="d-print-none d-flex justify-content-end align-items-center mr-3">
        <b-button @click="exportExcel" variant="primary" class="mr-3">Xuất Excel</b-button>
        <span @click="handleClose" class="cursor-pointer" style="font-size:40px">&times;</span>
      </div>
      <div class="container cs-container">
        <div ref="documentPrint" id="documentPrint">
          <div class="mb-4">
            <h2 class="text-uppercase text-center mt-2">{{ getLabel() }}</h2>
            <h4
              class="text-center"
            >{{reportData.pharma_inventory_info&&reportData.pharma_inventory_info.name}}</h4>
            <div class="text-center">
              <i>
                <span>Từ ngày</span>
                <span>{{ ` ${fromDate}` }}</span>
                <span>&nbsp;đến ngày</span>
                <span>{{ ` ${toDate}` }}</span>
              </i>
            </div>
          </div>
          <component
            :countExport="countExport"
            :title="getLabel()"
            :inventoryName="reportData.pharma_inventory_info&&reportData.pharma_inventory_info.name"
            :fromDate="fromDate"
            :toDate="toDate"
            :reportData="reportList"
            :is="componentReport"
            :allData="allData"
            @getReportDetail="getReportDetail"
          ></component>
        </div>
      </div>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class>
              Hiển thị {{ dataMeta.from }} đến {{ dataMeta.to }} của {{ dataMeta.of }}
              mục
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="pagination.currentPage"
              :total-rows="pagination.totalProducts"
              :per-page="pagination.perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import { REPORT_OPTIONS } from "@/utils/constant";
import BillReport from "./BillReport.vue";
import PharmaRevenue from "./PharmaRevenue.vue";
import MedicineSupplierReport from "./MedicineSupplierReport.vue";
import CashierReport from "./CashierReport.vue";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import BillReportV2 from "./BillReportV2.vue";
import PharmaRevenueV2 from "./PharmaRevenueV2.vue";
import InventoryReportV2 from "./InventoryReportV2.vue";

export default {
  name: "ReportLayout",
  components: {
    BillReport,
    PharmaRevenue,
    MedicineSupplierReport,
    CashierReport,
    BillReportV2,
    PharmaRevenueV2,
    InventoryReportV2
  },
  data() {
    return {
      reportList: [],
      reportData: {},
      countExport: 0,
      isLoading: false,
      pagination: {
        currentPage: 1,
        perPage: 10,
        totalProducts: 0
      },
      allData: []
    };
  },
  watch: {
    $route() {
      this.getReportDetail();
    },
    pagination: {
      deep: true,
      handler() {
        this.getReportDetail();
      }
    }
  },
  created() {
    this.getReportDetail();
  },
  computed: {
    componentReport() {
      let componentName = "";
      switch (this.reportType) {
        case REPORT_OPTIONS[0].value:
          componentName = "BillReportV2";
          break;
        // case REPORT_OPTIONS[1].value:
        //   componentName = "CashierReport";
        case REPORT_OPTIONS[1].value:
          componentName = "InventoryReportV2";
          break;
        //   break;
        case REPORT_OPTIONS[2].value:
          componentName = "PharmaRevenueV2";
          break;
        // case REPORT_OPTIONS[3].value:
        //   componentName = "MedicineSupplierReport";
        //   break;
      }
      return componentName;
    },
    fromDate() {
      const fromDateRoute = Number(this.$route.query.fromDate);

      return window
        .moment(fromDateRoute)
        .subtract(7, "hours")
        .format("DD/MM/YYYY");
    },
    toDate() {
      const toDateRoute = Number(this.$route.query.toDate);

      return window
        .moment(toDateRoute)
        .subtract(7, "hours")
        .format("DD/MM/YYYY");
    },
    reportType() {
      return Number(this.$route.params.id);
    },
    inventoryId() {
      return this.$route.query.inventoryId;
    },
    dataMeta() {
      const localItemsCount = this.reportList?.length || 0;
      return {
        from:
          this.pagination.perPage * (this.pagination.currentPage - 1) +
          (localItemsCount ? 1 : 0),
        to:
          this.pagination.perPage * (this.pagination.currentPage - 1) +
          localItemsCount,
        of: this.pagination.totalProducts
      };
    }
  },
  methods: {
    handleClose() {
      this.$router.replace({ name: "ReportManager" });
    },
    getLabel() {
      return (
        REPORT_OPTIONS.find(item => item.value === this.reportType)?.label || ""
      );
    },
    handlePrint() {
      window.print();
    },
    async exportExcel() {
      try {
        const isSuccess = await this.getReportDetail(true);
        if (isSuccess) {
          this.countExport++;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReportDetail(isAll) {
      if (!this.$route.params.id) return;
      this.isLoading = true;

      try {
        const fromDateRoute = Number(this.$route.query.fromDate);
        const toDateRoute = Number(this.$route.query.toDate);

        const params = {
          type: Number(this.$route.params.id),
          from_date: fromDateRoute,
          to_date: toDateRoute,
          pharma_inventory_id: this.$route.query.inventoryId,
          page_num: this.pagination.currentPage,
          page_size: isAll
            ? this.pagination.totalProducts
            : this.pagination.perPage
        };

        const response = await this.$store.dispatch(
          "reportStore/getReport",
          params
        );

        if (isAll) {
          this.allData = response?.data?.data?.data || [];
        } else {
          this.reportData = response?.data?.data || {};
          this.reportList = this.reportData?.data || [];
          this.pagination.totalProducts = this.reportData?.meta?.total;
        }

        return true;
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Có lỗi xảy ra khi lấy dữ liệu",
            icon: "AlertTriangleIcon",
            variant: "danger"
          }
        });
        return false;
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cs-container {
  position: relative;
  // min-height: calc(100vh - 1px);
  max-width: 100vw;
  overflow: hidden;
}

.cs-container * {
  color: #000;
  font-family: "Times New Roman", Times, serif;
  font-size: 18px;

  h2 {
    font-weight: 600;
    font-size: 26px;
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
  }
  h4 {
    font-size: 18px;
    font-weight: 600;
  }
  h5 {
    font-size: 16px;
    font-weight: 600;
  }

  .border-t {
    border-top: 1px solid #787878;
  }
  .border-b {
    border-bottom: 1px solid #787878;
  }
  .border-l {
    border-left: 1px solid #787878;
  }
  .border-r {
    border-right: 1px solid #787878;
  }
  .cs-border {
    border: 1px solid #787878;
  }
  .cs-capitalize {
    text-transform: lowercase;

    p::first-letter {
      text-transform: capitalize;
    }
  }
}
.cs-relative {
  position: relative;
}

.cs-text-bold {
  font-weight: 600;
}

.cs-close {
  display: block;
  position: absolute;
  top: -50px;
  right: 40px;
  // height: 30px;
  // width: 30px;
  font-size: 40px;
  z-index: 100;
  cursor: pointer;
}

.cs-font * {
  font-size: 16px;

  h4 {
    font-size: 20px;
  }
}
::v-deep .cs-btable * {
  color: #000;
  font-family: "Times New Roman", Times, serif;

  thead * {
    font-size: 18px;
    text-transform: none !important;
  }

  tbody {
    font-size: 18px;
  }
}

::v-deep .cs-btable {
  table {
    width: 2500px;
  }
}

.cs-print {
  margin: 0 !important;
}
@media print {
  html,
  body * {
    background: #fff !important;
  }
  .cs-print {
    display: block !important;
    overflow: hidden;

    .card-body {
      padding: 0 !important;
    }
  }

  ::v-deep .cs-btable * {
    color: #000;
    font-family: "Times New Roman", Times, serif;

    thead * {
      font-size: 12px;
      text-transform: none !important;
    }

    tbody {
      font-size: 12px;
    }

    tr {
      page-break-inside: avoid;
      page-break-after: auto;
    }
    thead {
      display: table-header-group;
    }
    tfoot {
      display: table-footer-group;
    }
  }
  table {
    page-break-inside: auto;
  }

  @page {
    size: landscape A4;
    margin: 0;
  }
}
</style>