<template>
  <div>
    <b-table-simple class="cs-table" bordered responsive small>
      <colgroup>
        <col width="20" />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col width="200" />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
      </colgroup>

      <b-thead>
        <b-tr>
          <b-td class="font-bold" rowspan="2" colspan="1">
            <div>STT</div>
          </b-td>
          <b-td class="font-bold" rowspan="2" colspan="1">
            <div>Mã thuốc</div>
          </b-td>
          <b-td class="font-bold" rowspan="2" colspan="1">
            <div>Tên thuốc</div>
          </b-td>
          <b-td class="font-bold" rowspan="2" colspan="1">
            <div>Hoạt chất</div>
          </b-td>
          <b-td class="font-bold" rowspan="2" colspan="1">
            <div>Hàm lượng</div>
          </b-td>
          <b-td class="font-bold" rowspan="2" colspan="1">
            <div>Đơn vị tính</div>
          </b-td>
          <b-td class="font-bold" rowspan="2" colspan="1">
            <div>Đường dùng</div>
          </b-td>
          <b-td class="font-bold" rowspan="2" colspan="1">
            <div>Hãng sản xuất</div>
          </b-td>
          <b-td class="font-bold" rowspan="2" colspan="1">
            <div>Nước sản xuất</div>
          </b-td>
          <b-td class="font-bold" rowspan="2" colspan="1">
            <div>Số lô</div>
          </b-td>
          <b-td class="font-bold" rowspan="2" colspan="1">
            <div>Số đăng ký</div>
          </b-td>
          <b-td class="font-bold" rowspan="2" colspan="1">
            <div>Giá nhập</div>
          </b-td>
          <b-td class="font-bold" rowspan="2" colspan="1">
            <div>Giá xuất</div>
          </b-td>
          <b-td class="font-bold" rowspan="2" colspan="1">
            <div>Hạn sử dụng</div>
          </b-td>
          <b-td class="font-bold" rowspan="2" colspan="1">
            <div>Tồn đầu kỳ</div>
          </b-td>
          <b-td class="text-center font-bold" rowspan="1" colspan="3">Nhập</b-td>
          <b-td class="text-center font-bold" rowspan="1" colspan="3">Xuất</b-td>
          <b-td class="font-bold" rowspan="2" colspan="1">
            <div>Tồn cuối kỳ</div>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td class="font-bold">Số lượng</b-td>
          <b-td class="font-bold">Giá nhập</b-td>
          <b-td class="font-bold">Thành tiền</b-td>
          <b-td class="font-bold">Số lượng</b-td>
          <b-td class="font-bold">Giá xuất</b-td>
          <b-td class="font-bold">Thành tiền</b-td>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(item,index) in reportData" :key="`${index}-${item.id}`">
          <b-td>{{index+1}}</b-td>
          <b-td>{{ item.product_code||'' }}</b-td>
          <b-td>{{ item.name}}</b-td>
          <b-td>{{ item.active_ingredient }}</b-td>
          <b-td>{{ item.quality }}</b-td>
          <b-td>{{ item.unit_name }}</b-td>
          <b-td>{{ item.use_route_name }}</b-td>
          <b-td>{{ item.manufacturer_name }}</b-td>
          <b-td>{{ item.country }}</b-td>
          <b-td>{{(item.lot_number)||'' }}</b-td>
          <b-td>{{ item.registration_number}}</b-td>
          <b-td>{{ appUtils.numberFormat(item.stock_in_price) }}</b-td>
          <b-td>{{ appUtils.numberFormat(item.stock_out_price) }}</b-td>
          <b-td>{{ formatExpiredDate(item) }}</b-td>
          <b-td>{{ Number(item.begin_period_num)||"" }}</b-td>
          <b-td>{{ Number(item.stock_in_num)?(appUtils.numberFormat(item.stock_in_num)):"" }}</b-td>
          <b-td>{{ Number(item.stock_in_price)?(appUtils.numberFormat(item.stock_in_price)):"" }}</b-td>
          <b-td>{{ Number(item.stock_in_total)?(appUtils.numberFormat(item.stock_in_total)) :""}}</b-td>
          <b-td>{{ Number(item.stock_out_num)? (appUtils.numberFormat(item.stock_out_num)):"" }}</b-td>
          <b-td>{{ Number(item.stock_out_price)?(appUtils.numberFormat(item.stock_out_price)):"" }}</b-td>
          <b-td>{{ Number(item.stock_out_total)?(appUtils.numberFormat(item.stock_out_total) ):""}}</b-td>
          <b-td>{{ Number(item.end_period_num)?item.end_period_num:"" }}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import ExcelJS from "exceljs";
import appUtils from "@/utils/appUtils";

export default {
  name: "BillReport",
  props: {
    reportData: Array,
    countExport: Number,
    title: String,
    inventoryName: String,
    fromDate: String,
    toDate: String,
    allData: Array
  },
  data() {
    return {
      appUtils
    };
  },
  watch: {
    countExport() {
      this.exportExcel();
    }
    // allData: {
    //   deep: true,
    //   handler() {
    //     if (this.allData?.length) {
    //       this.exportExcel();
    //     }
    //   }
    // }
  },
  methods: {
    async exportExcel() {
      const workbook = new ExcelJS.Workbook();

      const worksheet = workbook.addWorksheet("Sheet1", {
        views: [{ showGridLines: false }]
      });

      // Access columns from B to J and set their widths
      const defaultColWidth = 14;
      for (let index = 1; index <= 26; index++) {
        const column = worksheet.getColumn(index + 1); // Columns are 1-indexed
        if ([2, 7].includes(index)) {
          column.width = 28;
        } else {
          column.width = defaultColWidth;
        }
      }

      worksheet.properties.defaultRowHeight = 16;
      const START_ROW = 2;

      // Add a header rows and merged cells with style before main table
      const nameCell = worksheet.getCell(`J${START_ROW}`);
      nameCell.value = this.title.toUpperCase();
      nameCell.font = { size: 20, bold: true };
      worksheet.mergeCells(`J${START_ROW}:M${START_ROW}`);

      const inventoryNameCell = worksheet.getCell(`J${START_ROW + 1}`);
      inventoryNameCell.value = this.inventoryName?.toUpperCase();
      inventoryNameCell.font = { size: 12, bold: true };
      worksheet.mergeCells(`J${START_ROW + 1}:M${START_ROW + 1}`);

      const dateCell = worksheet.getCell(`J${START_ROW + 2}`);
      dateCell.value = `Từ ${this.fromDate} đến ${this.toDate}`;
      dateCell.font = { bold: false, italic: true };
      worksheet.mergeCells(`J${START_ROW + 2}:M${START_ROW + 2}`);
      console.log(this.allData);
      // Generate the main table (your data) with borders and style
      const rawData = this.allData.map((item, i) => {
        return [
          i + 1,
          item.product_code || "",
          item.name || "",
          item.active_ingredient || "",
          item.quality || "",
          item.unit_name || "",
          item.use_route_name || "",
          item.manufacturer_name || "",
          item.country || "",
          item.lot_number || "",
          item.registration_number || "",
          appUtils.numberFormat(item.stock_in_price) || "",
          appUtils.numberFormat(item.stock_out_price) || "",
          this.formatExpiredDate(item) || "",
          Number(item.begin_period_num) || "",
          Number(item.stock_in_num)
            ? appUtils.numberFormat(item.stock_in_num)
            : "",
          Number(item.stock_in_price)
            ? appUtils.numberFormat(item.stock_in_price)
            : "",
          Number(item.stock_in_total)
            ? appUtils.numberFormat(item.stock_in_total)
            : "",
          Number(item.stock_out_num)
            ? appUtils.numberFormat(item.stock_out_num)
            : "",
          Number(item.stock_out_price)
            ? appUtils.numberFormat(item.stock_out_price)
            : "",
          Number(item.stock_out_total)
            ? appUtils.numberFormat(item.stock_out_total)
            : "",
          Number(item.end_period_num) ? item.end_period_num : ""
          // item.begin_period_num || "",
          // appUtils.numberFormat(item.stock_in_num) || "",
          // appUtils.numberFormat(item.stock_in_price) || "",
          // appUtils.numberFormat(item.stock_in_total) || "",
          // appUtils.numberFormat(item.stock_out_num) || "",
          // appUtils.numberFormat(item.stock_out_price) || "",
          // appUtils.numberFormat(item.stock_out_total) || "",
          // item.end_period_num
        ];
      });
      const mainTableData = [
        [], // empty row
        [
          "STT",
          "MÃ THUỐC",
          "TÊN THUỐC",
          "HOẠT CHẤT",
          "HÀM LƯỢNG",
          "ĐƠN VỊ TÍNH",
          "ĐƯỜNG DÙNG",
          "HÃNG SẢN XUẤT",
          "NƯỚC SẢN XUẤT",
          "SỐ LÔ",
          "SỐ ĐĂNG KÝ",
          "GIÁ NHẬP",
          "GIÁ XUẤT",
          "HẠN SỬ DỤNG",
          "TỒN ĐẦU KỲ",
          "NHẬP",
          "",
          "",
          "XUẤT",
          "",
          "",
          "TỒN CUỐI KỲ"
        ],
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "SỐ",
          "GIÁ",
          "THÀNH TIỀN",
          "SỐ",
          "GIÁ",
          "THÀNH TIỀN",
          ""
        ],
        ...rawData
      ];

      worksheet.addRows(mainTableData);
      const mainTable = worksheet.getRows(6, mainTableData.length - 1);
      mainTable.forEach((row, rowIndex) => {
        row.eachCell(cell => {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
            bottom: { style: "thin" }
          };
          cell.font = { size: 12 };
          if (!rowIndex) {
            cell.font = { bold: true };
          }
        });
      });

      worksheet.mergeCells(6, 16, 6, 18);
      worksheet.mergeCells(6, 19, 6, 21);
      worksheet.mergeCells(6, 22, 7, 22);

      for (let index = 1; index <= 15; index++) {
        worksheet.mergeCells(6, index, 7, index);
      }

      worksheet.getCell("P7").font = { bold: true };
      worksheet.getCell("Q7").font = { bold: true };
      worksheet.getCell("R7").font = { bold: true };
      worksheet.getCell("S7").font = { bold: true };
      worksheet.getCell("T7").font = { bold: true };
      worksheet.getCell("U7").font = { bold: true };

      // default styles
      workbook.eachSheet(sheet => {
        sheet.eachRow(row => {
          row.alignment = {
            horizontal: "center",
            vertical: "middle",
            wrapText: true
          };
          row.eachCell(cell => {
            if (!cell.font?.name) {
              cell.font = Object.assign(cell.font || {}, {
                name: "Times New Roman"
                // size: 12
              });
            }
          });
        });
      });

      // Save the workbook to a file
      await workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "BaoCaoXuatNhapTon.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    formatExpiredDate(data) {
      const date =
        Number(data?.expiry_date_day) && Number(data?.expiry_date_day) < 10
          ? `0${data?.expiry_date_day}/`
          : data?.expiry_date_day
          ? `${data?.expiry_date_day}/`
          : "";

      const month =
        Number(data?.expiry_date_month) && Number(data?.expiry_date_month) < 10
          ? `0${data?.expiry_date_month}/`
          : data?.expiry_date_month
          ? `${data?.expiry_date_month}/`
          : "";
      return `${date}${month}${data?.expiry_date_year || ""}`;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .cs-table * {
  color: #000 !important;
}

::v-deep .cs-table {
  table {
    width: 2000px;
  }
}
</style>